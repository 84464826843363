<template>
  <v-row
    align="center"
    justify="center"
    no-gutters
  >
    <v-dialog
      v-model="pvDialog"
      fullscreen
      hide-overlay
      persistent
      scrollable
      style="background-color: #fff"
     >

      <v-container fluid class="pa-0 ma-0">
        <v-row
          align="center"
          justify="center"
          no-gutters
          style="background-color: #fff"
        >
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="pa-2 ma-2"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-toolbar
                  flat
                >
                  <h1 class="mx-1 my-2">
                    <v-icon class="mb-2">mdi-alert-circle-outline</v-icon>
                    개인정보처리방침
                  </h1>
                  <!-- <v-alert
                    border="top"
                    outlined
                    type="info"
                    dense
                  >
                    개인정보처리방침
                  </v-alert> -->
                  <v-spacer></v-spacer>
                  <v-icon
                    large
                    @click="closePrivacy"
                  >mdi-close</v-icon>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-alert
                  text
                  color="grey lighten-1"
                  class="pa-5"
                >

                  <h3 class="text--primary text-h6 pb-2">개인정보처리방침이란?</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>- 주식회사 현우엘앤씨가 개발, 운영하는 로워크는 이용자의 동의를 기반으로 개인정보를 수집, 이용, 관리함에 있어, 이용자의 권리를 적극적으로 보장하기 위해 관계 법령 및 가이드라인을 준수합니다.</p>
                      <p>- “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다.</p>
                    <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">1. 개인정보수집</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>로워크는 원활한 서비스를 제공하기 위한 최소한의 개인정보를 수집하며, 이를 사전에 이용자에게 알리고 동의를 구합니다.</strong><br>
                      <br>
                      <p>- 회원가입 : 이름, 이메일(Gmail)주소, 연락처, 구글앱엑세스 동의</p>
                      <p>- 조직생성 : 조직명, 연락처, 주소, 운영담당자정보, 액세스 토큰(Google Drive Access Token)</p>
                      <p>- 유료결제 : 지불게이트웨이 및 통신사를 통한 신용, 직불카드 정보 등</p>
                      <p>- 계산서(영수증) 발행 : 사업자등록번호, 상호, 대표자명, 전자계산서수신이메일, 현금영수증 카드번호,</p>
                      <p>- 서비스 이용과정에서 자동으로 생성, 수집될 수 있는 있는 IP Address, 쿠키, 방문일시, 서비스이용기록, 불량이용기록, 기기정보, 브라우저정보, 성능데이터</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">2. 개인정보이용</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>개인정보는 다음과 같은 목적으로 이용됩니다.</strong><br>
                      <br>
                      <p>- 이용자의 식별, 가입의사 확인, 불량회원 부정이용 방지</p>
                      <p>- 다양한 서비스 제공, 문의 및 불만 사항 처리, 공지사항 전달</p>
                      <p>- 유료서비스 결제 및 요금 정산</p>
                      <p>- 이벤트 행사 및 신규 서비스 등의 정보 전달, 마케팅 등에 활용</p>
                      <p>- 이용 기록 및 빈도 분석, 서비스 환경 개선 및 신규 구축, 맞춤형 서비스 제공 등에 활용</p>
                      <p>- 구글앱스의 활용</p>
                      <div class="ml-3">
                        <p>(1) 로그인 프로세스</p>
                        <p>로워크를 사용하기 위해 이용자는 본인 지메일 계정으로 구글 OAuth 를 거쳐 구글에 로그인을 요청하며, 구글로부터 승인된 유효한 억세스 토큰을 활용하여 로그인됩니다.</p>
                        <p>그러므로, 로워크는 이용자의 지메일계정은 수집하지만 비밀번호는 수집하지 않습니다.</p>
                        <br>
                        <p>(2) 지메일 활용</p>
                        <p>이용자는 본 앱을 공유하기 위해 공유대상자의 메일주소로 초대/확인 메일을 보낼 수 있으며, 업무용 메일을 보낼 수도 있습니다.</p>
                        <p>이 과정에서 https://www.googleapis.com/auth/gmail.send 를 이용하여 자신의 지메일 계정을 통해 메일을 발송합니다.</p>
                        <p>이용자는 자신의 지메일 계정에서 송수신 된 메일을 관리할 수 있습니다.</p>
                        <br>
                        <p>(3) 구글 드라이브 공유</p>
                        <p>이용자는 본인 계정의 구글 드라이브를 활용하여 해당 조직의 파일을 저장할 수 있으며, 공유 조직 내의 다른 이용자와 이를 공유할 수 있습니다.</p>
                        <p>이 과정에서 https://www.googleapis.com/auth/drive 를 이용하여 제한된 용량 이하의 파일을 업로드/다운로드 할 수 있습니다.</p>
                        <p>이용자는 자신의 구글 드라이브 계정에서 업로드 된 파일을 관리할 수 있습니다.</p>
                        <br>
                      </div>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">3. 개인정보제공</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>다양한 서비스를 제공하기 위해 필요한 최소한의 정보를 제3자에게 제공하고 있으며, 이를 변경시에는 해당 사실을 즉시 알립니다.</strong><br>
                      <br>
                      <p>- NHN 한국사이버결제 : 유료 결제 (이름, 로그인계정, 본인확인값)</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">4. 개인정보파기</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>개인정보수집의 목적이 달성된 후에는 지체없이 해당 정보를 파기합니다. 이는 복구 및 재생되지 않도록 기술적으로 완전하게 삭제하는 것을 의미합니다.</strong><br>
                      <strong>다만, 서비스 부정이용기록은 부정 가입 및 이용 방지를 위해 회원탈퇴시점부터 최대 1년간 보관 후 파기하며, 그 외 법령이 보관하도록 하는 정보의 해당 항목과 보존기간은 다음과 같습니다.</strong><br>
                      <br>
                      <p>- 서비스 이용 관련 개인정보(서비스 방문 기록) : 3개월 (통신비밀보호법)</p>
                      <p>- 표시, 광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자 보호에 관한 법률)</p>
                      <p>- 계약 또는 청약 철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자 보호에 관한 법률)</p>
                      <p>- 결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자 보호에 관한 법률)</p>
                      <p>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자 보호에 관한 법률)</p>
                      <p>- 전자금융 거래에 관한 기록 : 5년 (전자금융거래법)</p>
                      <p>- 1년 이상 서비스를 이용하지 않은 이용자의 개인정보 : 즉시 파기 또는 최대 4년간 별도로 분리 보관 후 파기 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">5. 쿠키</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>이용자에 대한 맞춤서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(Cookie)'를 사용합니다.<br>
                      쿠키는 서버가 이용자 브라우저에게 보내는 소규모의 데이터 꾸러미로서 귀하의 컴퓨터 하드 드라이버에 저장됩니다.</strong><br>
                      <br>
                      <p>
                      쿠키는 웹사이트와 PC간의 커뮤니케이션을 위해 PC에 저장되는 실행이 불가능한 작은 데이터로 PC의 다른 부분에는 전혀 영향을 주지 않습니다.<br>
                      이용자에게 더 나은, 그리고 보다 적절하고, 유용한 서비스를 전달하기 위해 쿠키를 사용하여 이용자를 인식하고, 이용자의 계정에 관한 정보를 찾아냅니다.<br>
                      이 쿠키는 이용자가 처음 회원등록을 하거나 "로그인"할 때 설정되며 서비스를 "로그아웃"할 때 수정됩니다.
                      </p>
                      <p>이용자는 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저 설정을 변경할 수 있습니다.</p>
                      <br>
                    </div>
                  </div>

                  <v-divider class="my-6"></v-divider>
                  <h3 class="text--primary text-h6 pb-2">6. 취급방침의 변경</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>개인정보취급방침에 대한 추가, 수정, 삭제시에는 시행일로부터 최소 일주일전에 공지합니다.</p>
                      <p>다만, 이용자의 권리 및 의무에 중대한 변경이 이루어지는 경우에는 최소 30일전에 공지하거나 개별통지하도록 합니다. 시행 후 별도의 의사표시가 없으면 변경된 방침에 동의한 것으로 간주합니다</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">7. 개인정보보호의 노력</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>로워크는 개인정보보호의 중요성에 대해 지대한 관심을 가지며, 다음과 같은 노력을 합니다.</strong><br>
                      <br>
                      <p>- 개인정보 암호화와 암호화된 통신을 통한 전송</p>
                      <p>- 개인정보의 위조, 변경, 무단의 액세스 방지를 위한 체계적인 조치</p>
                      <p>- 개인정보 보호를 위한 내부관리계획 구현</p>
                      <p>- 해당 정보에 대한 접근권한자의 최소화와 관리</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">8. 전담부서</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <strong>로워크는 개인정보보화와 관련된 질문이나 불편사항을 신속하고 철저하게 대응하기 위해 최선을 다하고 있습니다.</strong><br>
                      <br>
                      <p>- 담당 : 서지원</p>
                      <p>- 연락 : T 02-521-7505  E sjwfor@gmail.com</p>
                      <br>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>- 개인정보취급방침 공고일자 : 2020.03.12</p>
                      <p>- 개인정보취급방침 시행일자 : 2020.03.19</p>
                      <br>
                    </div>
                  </div>

                </v-alert>
              </v-col>
            </v-row>

            <!-- <v-divider class="my-3"></v-divider> -->

          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      pvDialog: true
    }
  },

  mounted () {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    closePrivacy () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/* .v-chip--small {
  height: 22px !important;
} */

/* .v-chip .v-chip__content {
  height: 22px;
  padding: 0 6px;
} */
</style>
